import React from 'react';
import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { tokens } from '../theme';

const Header = ({ title, subtile }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box mb="30px">
      <Typography
        variant={isMobile ? 'h4' : 'h2'}
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: '5px' }}
      >
        {title}
      </Typography>
      <Typography
        variant={isMobile ? 'h6' : 'h5'}
        color={colors.greenAccent[400]}
      >
        {subtile}
      </Typography>
    </Box>
  );
};

export default Header;
