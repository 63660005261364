import React from 'react';
import {
  useTheme,
  Container,
  Box,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import StVincentCarmel from '../assets/StVincentCarmel.png';

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="Home"
        subtile="Unethical - Ascenson St. Vincent - Beware - Home"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <img
            src={StVincentCarmel}
            alt="St Vincent Carmel"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '6px',
            }}
          />
        </Paper>
        {/* Paper with content */}
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            width: 'calc(100% + 40px)',
            margin: '20px -20px 20px -20px',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant={isMobile ? 'h4' : 'h1'}>
            <strong>Ascension St. Vincent Carmel ER</strong>
          </Typography>
          <Typography variant={isMobile ? 'h5' : 'h3'}>
            <strong>13500 North Meridian St Carmel, IN 46032</strong>
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h4' : 'h1'}>
            <strong>Ascension St. Vincent Indianapolis</strong>
          </Typography>
          <Typography variant={isMobile ? 'h5' : 'h3'}>
            <strong>2001 W 86th Street Indianapolis, IN 46260</strong>
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h4' : 'h2'}>
            <strong>
              Ascension St. Vincent Hospitals have 3,055 locations nationwide.
            </strong>
          </Typography>
          <Typography variant={isMobile ? 'h4' : 'h2'}>
            <strong>They are present in 16 States and 396 Cities.</strong>
          </Typography>
          <br />

          <Typography variant={isMobile ? 'h4' : 'h2'}>
            <strong>Yet they are </strong>
            <span style={{ color: 'red' }}>
              <strong>
                negligent, careless, unethical and unprofessional...
              </strong>
            </span>
          </Typography>
          <Typography variant={isMobile ? 'h4' : 'h2'}>
            <span style={{ color: 'red' }}>
              <strong>Horrible places which must be avoided...</strong>
            </span>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Home;
