import React from 'react';
import { useTheme, Container, Box, Paper, Typography } from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import BettyMessey from '../assets/BettyMassey.png';
import BettyMassy1 from '../assets/BettyMassyEmail-10-4-2023.png';
import BettyMassy2 from '../assets/BettyMasseyLetter-10-4-2023.png';
import BettyMassy3 from '../assets/BettyMassyEmail-10-5-2023.png';
import BettyMassy4 from '../assets/BettyMassyEmail-10-20-2023.png';
import BettyMassy5 from '../assets/BettyMasseyLetterFinalResolution-10-20-2023.png';

const BettyMasseyCorrespondance = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="BETTY MASSEY'S CORESSPONDANCE"
        subtile="Unethical - Ascenson St. Vincent - Beware - Betty Massey's (Consumer & Patient Experience Specialist) Correspondance"
      />

      <Paper
        elevation={5}
        sx={{
          bgcolor: colors.primary[400],
          alignItems: 'center',
          borderRadius: '6px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '20px',
        }}
      >
        <img
          src={BettyMessey}
          alt="Betty Messey"
          width="120px"
          height="120px"
          style={{ borderRadius: '10px' }}
        />
        <Box
          sx={{
            marginLeft: '40px',
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            BETTY MASSEY - CONSUMER & PATIENT EXPERIENCE SPECIALIST
            <br />
            <br />
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.redAccent[500] }}
            >
              This the person who thought I would go away easily...
            </Typography>
          </Typography>
        </Box>
      </Paper>

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* Paper with content */}
        {[BettyMassy1, BettyMassy2, BettyMassy3, BettyMassy4, BettyMassy5].map(
          (imgSrc, idx) => (
            <Paper
              elevation={5}
              sx={{
                bgcolor: colors.primary[400],
                borderRadius: '6px',
                padding: '20px',
                width: 'calc(100% + 40px)',
                // Top Right Bottom Left
                margin: '0 -20px 20px -20px',
              }}
            >
              <img
                src={imgSrc}
                alt={`Betty Massy Correspondance ${idx + 1}`}
                style={{ borderRadius: '6px', width: '100%', height: 'auto' }}
              />
            </Paper>
          )
        )}
      </Box>
    </Container>
  );
};

export default BettyMasseyCorrespondance;
