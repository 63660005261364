import React from 'react';
import {
  useTheme,
  Container,
  Box,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import StVincentCarmelWeb1 from '../assets/StVincentCarmelWeb1.png';
import StVincentCarmelWeb2 from '../assets/StVincentCarmelWeb2.png';

const StVWebURL =
  'https://healthcare.ascension.org/locations/indiana/ineva/carmel-ascension-st-vincent-carmel-emergency';

const TheirClaim = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="THEIR CLAIM"
        subtile="Unethical - Ascenson St. Vincent - Beware - Their Claim"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            width: 'calc(100% + 40px)',
            margin: '0 -20px 20px -20px',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant={isMobile ? 'h4' : 'h3'}>
            St. Vincent Carmel's claim for 24/7 emergency care.
            <br />
            <br />
            <strong style={{ color: 'red' }}>
              My question is, where was this emergency care, when I needed it?
            </strong>
          </Typography>
          <br />
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            sx={{ marginLeft: '20px', marginRight: '20px' }}
          >
            Here is a screenshot of the Ascension St. Vincent Carmel website. I
            have highlighted the claim they make over and over. Or if you wish,
            you can visit their website to see for yourself at... <br />
            <br />
            <Box
              component="a"
              href={StVWebURL}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                color: colors.blueAccent[300],
              }}
            >
              <strong>St. Vincent Carmel ER...</strong>
            </Box>
          </Typography>
        </Paper>

        {/* Paper with content */}
        {[StVincentCarmelWeb1, StVincentCarmelWeb2].map((imgSrc, idx) => (
          <Paper
            elevation={5}
            sx={{
              bgcolor: colors.primary[400],
              borderRadius: '6px',
              padding: '20px',
              width: 'calc(100% + 40px)',
              // Top Right Bottom Left
              margin: '0 -20px 20px -20px',
            }}
          >
            <img
              src={imgSrc}
              alt={`Their Claim ${idx + 1}`}
              style={{ borderRadius: '6px', width: '100%', height: 'auto' }}
            />
          </Paper>
        ))}
      </Box>
    </Container>
  );
};

export default TheirClaim;
