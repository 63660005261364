import React, { useState } from 'react';
import {
  useTheme,
  Container,
  Box,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS serviceId, templateId, publicKey
    const serviceId = 'service_xat3u9d';
    const templateId = 'template_j2j3d1k';
    const publicKey = 'p8QPvAGXuiJ-ZAfuf';

    const msg = document.getElementById('msg');

    // Create a new object that contains dynamic template params
    const templateParams = {
      name: name,
      email: email,
      to_name: 'Me',
      message: message,
    };

    // Send email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        setTimeout(function () {
          msg.innerHTML = 'Message sent sucessfully!';
        }, 0);
        setTimeout(function () {
          msg.innerHTML = '';
        }, 5000);
        clearTimeout();
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        setTimeout(function () {
          msg.innerHTML = 'Error in sending email!';
        }, 0);
        setTimeout(function () {
          msg.innerHTML = '';
        }, 5000);
      });
  };

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="CONTACT ME"
        subtile="Unethical - Ascenson St. Vincent - Beware - Contact Me"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* Paper with content */}
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '10px',
            padding: '20px',
            width: 'calc(100% + 40px)',
            margin: '0 -20px',
          }}
        >
          <Box sx={{ marginBottom: '10px' }}>
            <TextField
              label="Name"
              name="name"
              value={name}
              fullWidth
              required
              onChange={(e) => setName(e.target.value)}
              sx={{
                '& .MuiInputBase-root': {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 100px ${colors.primary[400]} inset`, // Match your background color
                    WebkitTextFillColor: colors.grey[100], // Match your text color
                    transition: 'background-color 5000s ease-in-out 0s', // Prevents background reset
                  },
                },
                '& .MuiInputLabel-root': {
                  color: colors.grey[100], // Default label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100], // Focused label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100], // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: colors.grey[100], // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.grey[100], // Focused border color
                  },
                },
              }}
            ></TextField>
          </Box>
          <Box sx={{ marginBottom: '10px' }}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={email}
              fullWidth
              required
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: colors.grey[100], // Default label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100], // Focused label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100], // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: colors.grey[100], // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.grey[100], // Focused border color
                  },
                },
              }}
            ></TextField>
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <TextField
              label="Message"
              name="message"
              value={message}
              multiline
              rows={6}
              fullWidth
              required
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: colors.grey[100], // Default label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100], // Focused label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100], // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: colors.grey[100], // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.grey[100], // Focused border color
                  },
                },
              }}
            ></TextField>
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{ bgcolor: colors.blueAccent[500] }}
          >
            Send Email
          </Button>
        </Paper>
        <span id="msg"></span>
      </Box>
    </Container>
  );
};

export default Contact;
