import React, { useState, useEffect } from 'react';
import {
  useTheme,
  Container,
  Box,
  Paper,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import { collection, addDoc, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Import Firestore instance

const Comments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [comments, setComments] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comment: '',
  });

  // Fetch comments from Firestore
  useEffect(() => {
    const fetchComments = async () => {
      const querySnapshot = await getDocs(collection(db, 'comments'));
      const fetchedComments = querySnapshot.docs
        .map((doc) => doc.data())
        .sort((a, b) => b.timestamp?.toMillis() - a.timestamp?.toMillis()); // Sort by timestamp
      setComments(fetchedComments);
    };

    fetchComments();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.comment) {
      alert('Please fill in all fields');
      return;
    }

    try {
      const commentWithTimestamp = {
        ...formData,
        timestamp: Timestamp.now(), // Add timestamp
      };

      const docRef = await addDoc(
        collection(db, 'comments'),
        commentWithTimestamp
      );
      console.log('Document written with ID: ', docRef.id);

      // Update local comments list
      setComments((prev) => [...prev, commentWithTimestamp]);

      // Reset the form
      setFormData({ name: '', email: '', comment: '' });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="COMMENTS"
        subtile="Unethical - Ascenson St. Vincent - Beware - Comments"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* Paper with content */}
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            width: 'calc(100% + 40px)',
            margin: '0 -20px',
          }}
        >
          <Box sx={{ marginBottom: '10px' }}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              required
              sx={{
                '& .MuiInputBase-root': {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 100px ${colors.primary[400]} inset`, // Match your background color
                    WebkitTextFillColor: colors.grey[100], // Match your text color
                    transition: 'background-color 5000s ease-in-out 0s', // Prevents background reset
                  },
                },
                '& .MuiInputLabel-root': {
                  color: colors.grey[100], // Default label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100], // Focused label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100], // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: colors.grey[100], // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.grey[100], // Focused border color
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ marginBottom: '10px' }}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              required
              sx={{
                '& .MuiInputLabel-root': {
                  color: colors.grey[100], // Default label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100], // Focused label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100], // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: colors.grey[100], // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.grey[100], // Focused border color
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <TextField
              label="Comment"
              name="comment"
              value={formData.comment}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={6}
              required
              sx={{
                '& .MuiInputLabel-root': {
                  color: colors.grey[100], // Default label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.grey[100], // Focused label color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.grey[100], // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: colors.grey[100], // Hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.grey[100], // Focused border color
                  },
                },
              }}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            onClick={handleFormSubmit}
            sx={{ bgcolor: colors.greenAccent[700] }}
          >
            Submit Comment
          </Button>
        </Paper>

        {/* Comments Section */}
        <Box sx={{ mb: '20px' }}></Box>
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            width: 'calc(100% + 40px)',
            margin: '0 -20px',
          }}
        >
          <Box>
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
              sx={{ color: colors.blueAccent[300] }}
            >
              ALL COMMENTS
            </Typography>
            {comments.length === 0 ? (
              <Typography variant="body1">
                No comments yet. Be the first to comment!
              </Typography>
            ) : (
              comments.map((c, index) => (
                <Paper
                  key={index}
                  sx={{
                    padding: 2,
                    marginBottom: 2,
                    borderRadius: '8px',
                    backgroundColor: '#e0e0e0',
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.blueAccent[500]}
                  >
                    {c.name}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    {c.timestamp?.toDate().toLocaleString() || 'Unknown time'}
                  </Typography>
                  <Typography variant="h5" color="primary">
                    {c.comment}
                  </Typography>
                </Paper>
              ))
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Comments;
