import React from 'react';
import { Paper, Typography } from '@mui/material';

const Warning = () => {
  return (
    <Paper
      elevation={5}
      sx={{
        bgcolor: 'red',
        border: 'solid',
        borderRadius: '6px',
        borderWidth: '1px',
        padding: '15px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginLeft: 'auto ',
        marginRight: 'auto',
        marginTop: '45px',
        marginBottom: '30px',
        display: {
          xs: 'flex',
          md: 'flex',
        },
      }}
    >
      <Typography
        color="white"
        fontWeight="bold"
        sx={{ fontSize: { xs: '16px', md: '27px' } }}
      >
        {/* This is being published to inform and hopefully protect residents of 16
        States and 396 Cities where Ascension provides services. */}
        This is being shared to inform and safeguard the residents across 16
        states and 396 cities served by Ascension.
      </Typography>
    </Paper>
  );
};

export default Warning;
