import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        width: '100%',
        display: { xs: 'none', sm: 'flex' }, // Ensure flexbox is used for centering
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        textAlign: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '50px',
        bgcolor: colors.primary[400], // Background color
      }}
    >
      <Typography variant="h5">
        © 2024 Faruk Ansari. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
