// Import the functions you need from the SDKs
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBN4Uy9kYduXn95zv_kiLsINiIBjSg3H8w',
  authDomain: 'stvincentcomments.firebaseapp.com',
  projectId: 'stvincentcomments',
  storageBucket: 'stvincentcomments.firebasestorage.app',
  messagingSenderId: '230557869505',
  appId: '1:230557869505:web:bd76bf58868d560b837b40',
  measurementId: 'G-3X5FPH1V97',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

export { db, analytics };
